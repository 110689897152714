<template>
    <div v-if="openSearch" class="modal">
            <div class="modal-wrapper">
                <div class="modal-header">
                    <div class="title">{{lang.get("SELECT_TOKEN")}}</div>
                    <button @click="$emit('close')" data-cv="btn-close-select-token"> <i class="i-close"></i></button>
                </div>
                <div class="modal-content">
                    <!-- <div class="input input-search">
                        <div class="icon-search"><i class="i-search"></i></div>
                        <input type="text" placeholder="Choose your token" v-model="tokenInput">
                        <i class="i-load"></i>
                    </div> -->
                    <ul class="list-select list-select_token" data-cv="list-select-token">
                      <li v-for="token in searchToken()" :key="token.address" :value="token.address" :name="token.name" @click="$emit('tokenSelected', $event)" >
                        <div class="token_img">
                          <div :class="token.name" :value="token.address" :name="token.name"></div>
                        </div>

                        {{ tokenTicker(token.name) }}
                        
                        <i v-if="selectedToken && selectedToken === token.name" class="i-check-full" :value="token.address" :name="token.name"></i>
                        <!-- <i v-else class="i-arrow"></i> -->
                        </li> 
                        
                        
                    </ul>
                </div>
            </div>
        </div>
</template>

<script>
import MultiLang from '@/core/multilang'; 
import {mapState} from 'vuex';
import Config from '../../../Config.json'
export default {
  props:['openSearch', 'selectedToken'],
  data: function() {
    return {
      lang: new MultiLang(this),
      tokenInput: '', //v-model
      stakeTokens: Config.stakeTokens
    }
  },
  mounted() {
  this.lang.init()
  },
  computed: {
      ...mapState['allTokens']
  },

  methods: {
      searchToken() {
          return this.stakeTokens.filter(token => token.name.includes(this.tokenInput.toUpperCase()))
      },
      tokenTicker(tokenName) {
        return Config.tokenAliases[tokenName];
      }
  }
}
</script>