<template>
    <div v-if="showStakeWindow" class="modal modal_new_stake">
       <div class="modal-wrapper">
          <div class="modal-header">
              <div class="title">{{lang.get("DEPOSIT_TITLE")}} </div>
              <button @click="$emit('closeStakeWindow')" data-cv="btn-closeStakeWindow"> <i class="i-close"></i></button>
          </div>
          <div class="modal-content modal-content_stake ">

            <div class="modal-block">
              <div class="modal-block-title">
                {{lang.get("STAKE_AMOUNT")}}
              </div>
              <div class="input">
                <div style="width:100% ; text-align: right">{{stakeAmount}}</div>
                <!-- <button @click="setMaxValue()" class="button button_max">max</button> -->
              </div>
              <div class="addinfo_container">
                <div class="addinfo">
                  <i class="i-balance"></i>
                  <span>{{lang.get("WALLET_BALANCE")}}&nbsp;{{tokenBalance}}</span>
                </div>
                <div class="addinfo">
                  <i class="i-chart"></i>
                  <span>{{lang.get("MAX_STAKE_LIMIT")}}&nbsp;{{pool ? pool.totalMaxStake.toLocaleString() : 0}} </span>
                </div>
                <div class="addinfo">
                  <i class="i-calendar"></i>
                  <span>90 {{lang.get("DAYS")}}</span>
                </div>
              </div>
              <div class="modal-block-title">
                {{lang.get("EXPECTED_REWARD")}}
              </div>
              <ul v-if="pool" class="list-expected_reward"  data-cy="list-expected_reward-newstake">
              <li v-for="farmToken in pool.farmTokensList" :key="farmToken.address">  
                <div class="token_img">
                  <div :class="farmToken.name"></div>
                </div>
                <div class="title-description">{{tokenTicker(farmToken.name)}} </div>
                <div class="value-description">{{farmToken.possibleReward ? farmToken.possibleReward.toFixed(4) : "0.0000"}}</div>
              </li>
              </ul>
            </div>

            <div class="modal-block">              
              <div class="modal-block-title">
                {{lang.get("REFERRER_WALLET")}}
              </div>
              <div v-if="getUplineAddress" class="status_upline upline">
                <!--------------------- noupline -->
                <i></i> {{lang.get("YOUR_REFERRER_WALLET")}} <span class="status">{{getUplineAddress}}</span>
              </div>
              <div v-else class="status_upline upline">
                <!--------------------- noupline -->
                <i></i> {{lang.get("YOUR_REFERRER_WALLET")}} <span class="status"> {{lang.get("NO_REFERRER")}}</span>
              </div>
              <div v-if="!validReferrer" class="inform">
                  <i class="i-attention"></i>
                  <div>
                      {{lang.get("NO_REFERRER_MESSAGE")}}
                  </div>
              </div>
            </div>
              <div class="container_button">
                 <button :disabled="tokensApproved" @click="approve()" class="button button_approve" data-cv="btn-tokensApproved-disabled">{{lang.get("APPROVE")}}</button>
                <button :disabled="!tokensApproved" @click="stake()" class="button button_stake" data-cv="btn-tokensNotApproved-disabled">{{lang.get("STAKE")}}</button>
              </div>
            <div class="container_status_step">
            
              <div class="step_number " :class="{active: tokensApproved}">
                <div class="number">1</div>
                <i></i>
              </div>
              <div class="step_line"></div>
              <div class="step_number">
                <div class="number">2</div>
                <i></i>
              </div>
            </div>
           

        </div>
      </div>
    </div>
</template>

<script>
import MultiLang from '@/core/multilang'; 
import { mapState } from 'vuex';
import Config from '../../../Config.json'
import {ethers} from 'ethers';

export default {
  props: ['tokenAddress','showStakeWindow', 'stakeAmount','tokenContract', 'tokenBalance', 'pool'],
  data: function() {
    return {
      lang: new MultiLang(this),
      stakeSucceded: false,
      tokensApproved: false,
      validReferrer: true,
      intervalUpdate: null

    }
  },

  mounted() {
  this.lang.init()

    let _this = this;

    setTimeout(async function init() {
      try {

        const allowance = ethers.utils.formatUnits(await _this.tokenContract.allowance(_this.currentAddress, Config.FARM_ADDRESS));
        const referrerAddress = localStorage.getItem('ref') || ""
        if(referrerAddress.length == 42 && referrerAddress !== _this.currentAddress){
          const refStatus = await _this.$root.core.checkReferrer(referrerAddress)
          _this.validReferrer = refStatus;
        }else {
          _this.validReferrer = false
        }
        

        

        if(Number(allowance) >= Number(_this.stakeAmount)){
          _this.tokensApproved = true
        }

        // const poolData = _this.farmTokens.find(pool => pool.tokenAddress === _this.tokenAddress);

        // for(let i= 0; i< poolData.farmTokensList.length; i++) {
        //   const dailyReward = await _this.$root.core.getDailyReward(_this.stakeAmount, poolData.tokenAddress,poolData.farmTokensList[i].address)
        //   const possibleReward = Number(dailyReward) * (90 - poolData.farmTokensList[i].interval)
        //   poolData.farmTokensList[i].possibleReward = Number(possibleReward)

        // }
        // _this.pool = poolData

      // if(_this.pool == undefined){setTimeout(init, 300)};
      _this.intervalUpdate = setInterval(()=> {_this.$forceUpdate()}, 2000) //TODO discuss
      } catch (ex) {
        console.log(ex);
          setTimeout(init, 300);
      }
    },0);
  },


  beforeDestroy() {
    clearInterval(this.intervalUpdate)
  },

  computed: {
      ...mapState(['currentAddress', 'farmTokens']),
      getUplineAddress() {
            return localStorage.getItem('ref') || "" ;
      },
  },
  methods: {
      tokenTicker(tokenName) {
        return Config.tokenAliases[tokenName];
      },
      async stake(){
      
        try {
          let ref = localStorage.getItem('ref') 
           
           let rawStakeTransaction = await this.$root.core.stake(this.tokenAddress, this.stakeAmount, this.pool.decimals, ref);
           this.$emit('waitStake');
           

            let resultStake = await rawStakeTransaction.wait();
            
            if(resultStake.transactionHash) {
              this.$emit('close');
              this.$emit('closeStakeWindow');
              this.$emit('stakeSuccess')
              setTimeout(() => this.$emit('close'), 3000)
            }

                
            this.stakingAmount = 0;
        } catch (error) {
          this.$emit('showError')
        }
      },

      async approve() {
        
           
           try {
             let rawTransaction = await this.$root.core.approve(this.tokenContract, this.stakeAmount, this.pool.decimals) ;
             this.$emit('waitApprove');
            

              let resultAllowance = await rawTransaction.wait();
              
              if(resultAllowance.transactionHash){
                this.$emit('close')
                this.$emit('approveSuccess')
                setTimeout(() => this.$emit('close'), 3000)
                this.tokensApproved = true;
              }
           } catch (error) {
             console.log(error)
             this.$emit('close')
             this.$emit('showError')
           }
      }
  }
}
</script>